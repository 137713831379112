import { Workbox } from 'workbox-window';
import * as toast from './utils/toast';

const toastOptions = {
    message: {
        backgroundColor: '#2d3f4b',
        color: '#FFFFFF',
    }
};
toast.init(toastOptions);

const isDevelopment = process.env.NODE_ENV === 'development';
let refreshing = false;

if ('serviceWorker' in navigator && !isDevelopment) {
    const wb = new Workbox('/sw.js');

    wb.addEventListener('waiting', (event) => {
        wb.messageSW({ type: 'SKIP_WAITING' }).catch((error) =>
            console.error(error)
        );
    });

    wb.addEventListener('installed', event => {
        if (event.isUpdate) {
            if (refreshing) {
                return;
            }
            refreshing = true;

            toast.add(
                'Eine neue Version der Seite ist verfügbar, lade neu...',
                () => location.reload()
            );
        }
    });

    window.addEventListener('load', () => wb.register());
}
