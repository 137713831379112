/**
 * scrolls to position
 * @param {number} to
 * @param {number} duration
 * @param {function} callback
 * @returns {void|function}
 */
export function scrollTo(to, duration = 500, callback = () => {}) {
    const start =
        document.documentElement.scrollTop ||
        document.body.parentNode.scrollTop ||
        document.body.scrollTop;
    const change = to - start;
    const increment = 20;
    let currentTime = 0;

    const animateScroll = () => {
        currentTime = currentTime + increment;

        const val = ((t, b, c, d) => {
            t = t / (d / 2);

            if (t < 1) {
                return c / 2 * t * t + b;
            }

            t--;
            return -c / 2 * (t * (t - 2) - 1) + b;
        })(currentTime, start, change, duration);

        document.documentElement.scrollTop = val;
        document.body.parentNode.scrollTop = val;
        document.body.scrollTop = val;

        if (currentTime < duration) {
            (window.requestAnimationFrame ||
                window.webkitRequestAnimationFrame ||
                window.mozRequestAnimationFrame ||
                function(callback) {
                    window.setTimeout(callback, 1000 / 60);
                })(animateScroll);
        } else if (callback && typeof callback === 'function') {
            return callback();
        }
    };

    animateScroll();
}
