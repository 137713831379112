import { scrollTo } from '../utils/scroll-to';

let $formWrapper = document.querySelector('form#register');

let $formError = null;
let $formNotice = null;
let $formPortal = null;
let $portalText = null;
let $requiredFields = null;
let $requiredCheckboxes = null;
let $uploadFields = null;
let $submitButton = null;
let $terms = null;

let submitForm = false;

/**
 * Toggles the view state of the error
 *
 * @param show
 * @returns {void}
 */
const toggleFormError = (show = true) => {
    const $erroField = $formNotice || $formError;

    if (show) {
        submitForm = false;

        $erroField?.classList.remove('hidden');
    } else {
        $erroField?.classList.add('hidden');
    }
};

/**
 * Checks the checkbox validation
 *
 * @param scroll
 * @return {boolean}
 */
const checkboxValidation = (scroll = true) => {
    let firstOffsetTop = null;
    let valid = true;

    Array.prototype.forEach.call($requiredCheckboxes, ($group) => {
        const $checkboxes = $group.querySelectorAll('input[type="checkbox"]');
        if ($checkboxes.length < 0) {
            return;
        }

        $group.classList.remove('required');
        let checked = 0;

        Array.prototype.forEach.call($checkboxes, ($checkbox) => {
            if ($checkbox.checked) {
                checked++;
            }
        });

        if (checked < 1) {
            valid = false;
            submitForm = false;

            $group.classList.add('required');

            if (!firstOffsetTop) {
                firstOffsetTop = $group.offsetTop;
            }
        }
    });

    if (firstOffsetTop && scroll) {
        scrollTo(firstOffsetTop);
        firstOffsetTop = null;
    }

    return valid;
};

/**
 * Handles the submit
 *
 * @param event
 * @returns {void}
 */
const handleFormSubmit = (event = null) => {
    if (submitForm) {
        return;
    }

    let valid = true;
    event?.preventDefault();

    Array.prototype.forEach.call($requiredFields, ($field) => {
        const state = validationListener({ target: $field });

        if (!state) {
            valid = false;
        }
    });

    if ($terms && !$terms.checked) {
        valid = false;
        $terms.parentNode.classList.add('required');
    }

    if (!checkboxValidation()) {
        valid = false;
        toggleFormError();
    }

    if (valid) {
        toggleFormError(false);
        submitForm = true;
        $submitButton.click();
    }
};

/**
 * Adds checkbox validation listener
 *
 * @returns {void}
 */
const addCheckboxValidationListener = () => {
    Array.prototype.forEach.call($requiredCheckboxes, ($group) => {
        const $checkboxes = $group.querySelectorAll('input[type="checkbox"]');

        Array.prototype.forEach.call($checkboxes, ($checkbox) =>
            $checkbox.addEventListener('change', () => checkboxValidation())
        );
    });
};

/**
 * Returns the
 * @param $element
 * @return {{top: number}}
 */
const getOffset = ($element) => {
    const rect = $element.getBoundingClientRect();

    return {
        top: rect.top + window.scrollY,
    };
};

/**
 * Handles the click on an invalid submit
 *
 * @param event
 * @returns {void}
 */
const handleSubmitClick = (event) => {
    if (submitForm) {
        return handleFormSubmit();
    }

    event.preventDefault();
    let valid = true;

    Array.prototype.forEach.call($requiredFields, ($field) => {
        const state = validationListener({ target: $field });

        if (!state) {
            valid = state;
        }
    });

    if ($terms && !$terms.checked) {
        valid = false;
        $terms.parentNode.classList.add('required');
    } else if ($terms && $terms.checked) {
        $terms.parentNode.classList.remove('required');
    }

    if (!checkboxValidation(false)) {
        valid = false;
    }

    if (!valid) {
        scrollTo(getOffset($formWrapper).top);
    } else {
        handleFormSubmit();
    }
};

/**
 * Tests the validity of an input
 *
 * @param event
 * @returns boolean
 */
const validationListener = (event) => {
    const $target = event.target;

    if (!$target) {
        return false;
    }

    if ($target.validity.valid) {
        $target.parentNode.parentNode
            .querySelector('label')
            ?.classList.remove('label--not-validated');
        $target.classList.remove('input--not-validated');
        toggleFormError(false);

        if ($target.type === 'radio') {
            // noinspection JSUnresolvedVariable
            $target.parentNode.parentNode.classList.remove('required');
        }

        return true;
    } else {
        $target.parentNode.parentNode
            .querySelector('label')
            ?.classList.add('label--not-validated');
        $target.classList.add('input--not-validated');
        toggleFormError(true);

        if ($target.type === 'radio') {
            // noinspection JSUnresolvedVariable
            $target.parentNode.parentNode.classList.add('required');
        }

        return false;
    }
};

/**
 * Registers the dom objects.
 *
 * @returns {void}
 */
const registerDomObjects = () => {
    $formError = document.querySelector('.form__error');
    $formNotice = document.querySelector('.form__notice');
    $formPortal = $formWrapper?.querySelector('.form__portal select');
    $portalText = $formWrapper?.querySelector('.form__portaltext');
    $requiredFields = $formWrapper?.querySelectorAll('[required]');
    $requiredCheckboxes = $formWrapper?.querySelectorAll('[data-required="1"]');
    $uploadFields = $formWrapper?.querySelectorAll('div[data-upload-form]');

    $submitButton = $formWrapper?.querySelector('input[type="submit"]');
    if (!$submitButton) {
        $submitButton = $formWrapper?.querySelector('button[type="submit"]');
    }

    $terms = $formWrapper?.querySelector(
        'input[type="checkbox"][name="eventform-privacy"]'
    );
};

/**
 * Initiliaze the form validation
 *
 * @returns {void}
 */
export const init = () => {
    if (!$formWrapper) {
        return;
    }

    registerDomObjects();
    addCheckboxValidationListener();

    $formWrapper.addEventListener('submit', (event) => handleFormSubmit(event));

    Array.prototype.forEach.call($requiredFields, ($field) =>
        $field.addEventListener('blur', (event) => validationListener(event))
    );

    $submitButton?.addEventListener('click', (event) =>
        handleSubmitClick(event)
    );

    $terms?.addEventListener('change', () => {
        if ($terms.checked) {
            // noinspection JSUnresolvedVariable
            $terms.parentNode.classList.remove('required');
        } else {
            // noinspection JSUnresolvedVariable
            $terms.parentNode.classList.add('required');
        }
    });
};
