import Headroom from "headroom.js";

import './assets-loader';
import './polyfills';
import './service-worker';
import './vendor/cookie-modal';

import * as menu from './components/menu';
import * as cookieModal from './components/cookie-modal';
import * as filter from './components/filter';
import * as formValidation from './components/form-validation';
import * as scrollControl from './components/scroll-control';
import * as map from "./components/map";

const myElement = document.querySelector(".js-headroom");
const headroom = new Headroom(myElement, {
  "offset": 0,
  "tolerance": 0,
});
// noinspection JSUnresolvedFunction
headroom.init();

menu.init();
cookieModal.init();
filter.init();
formValidation.init();
scrollControl.init();
map.init();
