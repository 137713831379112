const desktopWidth = 1280;
const $header = document.querySelector('header.navbar');
const $dropdowns = $header?.querySelectorAll(
    'ul.menu-flyout > .menu-flyout__item > .menu-flyout__link:not(.menu-flyout__link--no-children)'
);
const $singles = $header?.querySelectorAll(
    'ul.menu-flyout > .menu-flyout__item > .menu-flyout__link.menu-flyout__link--no-children'
);
const $closer = $header?.querySelectorAll('.menu-flyout__closer');
const $burger = $header?.querySelector('.burger');

let menuOpen = false;
let $activeElement = null;

/**
 * Hides the navigation overlay
 *
 * @return {void}
 */
const removeActiveSubNavigation = () => {
    const $selected = $header?.querySelector('.menu-flyout--show');

    $selected?.classList.remove('menu-flyout--show');
};

/**
 * Handles activation toggle.
 *
 * @param $item
 * @returns {void}
 */
const handleSubNavigation = $item => {
    const $subItem = $item.nodeName === 'DIV' ? $item.parentNode : $item;

    removeActiveSubNavigation();

    if ($subItem === $activeElement) {
        console.log('equal');
        $subItem.classList.remove('menu-flyout--show');
        $activeElement = null;
    } else {
        console.log('not equal');
        $subItem.classList.add('menu-flyout--show');
        $activeElement = $subItem;
    }
};

/**
 * Handles hover and click on navigation
 *
 * @param $item
 * @returns {void}
 */
const handleActivation = ($item) => {
    $item.addEventListener('mouseenter', () => {
        if (desktopWidth < window.innerWidth) {
            handleSubNavigation($item);
        }
    });
    $item.addEventListener('click', () => {
        if (desktopWidth >= window.innerWidth) {
            handleSubNavigation($item);
        }
    });

    $header.addEventListener('mouseleave', () => removeActiveSubNavigation());
};

/**
 * Initiliaze the dropdown navigation
 *
 * @returns {void}
 */
export const init = () => {
    if ($closer) {
        Array.prototype.forEach.call($closer, ($close) =>
            $close.addEventListener('click', () => removeActiveSubNavigation())
        );
    }

    if ($dropdowns) {
        Array.prototype.forEach.call($dropdowns, ($item) =>
            handleActivation($item)
        );
    }

    if ($singles) {
        Array.prototype.forEach.call($singles, ($single) => {
            $single.addEventListener('mouseenter', () =>
                removeActiveSubNavigation()
            );
        });
    }

    if ($closer) {
        Array.prototype.forEach.call($closer, () =>
            removeActiveSubNavigation()
        );
    }

    $burger?.addEventListener('click', () => {
        if (!menuOpen) {
            document.body.classList.add('js-menu--open');
            menuOpen = true;
        } else {
            document.body.classList.remove('js-menu--open');
            menuOpen = false;

            removeActiveSubNavigation();
        }
    });
};
