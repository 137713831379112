const $filters = document.querySelectorAll('[data-filter-target]');
const $reset = document.querySelector('[data-filter-reset]');
const $elements = document.querySelectorAll('[data-filter-item]');

/**
 * Current state of the filter
 *
 * @type {{current: {}, filters: *[], items: *[], filterCount: number}}
 */
const state = {
    filterCount: 0,
    filters: [],
    items: [],
    current: {},
};

/**
 * Handles the state change
 *
 * @returns {void}
 */
const handleStateChange = () => {
    state['items'].forEach((item) => {
        let counter = 0;

        state['filters'].forEach((filter) => {
            if (filter.value !== '') {
                if (item.filter[filter.filter].indexOf(filter.value) > -1) {
                    counter++;
                }
            }
        });

        if (counter) {
            item.element.classList.add('filter--visible');
        } else {
            item.element.classList.remove('filter--visible');
        }
    });
};

/**
 * Resets the state
 *
 * @returns {void}
 */
const resetElements = () => {
    state.filters.forEach((filter) => {
        filter.value = '';
    });

    state.items.forEach((item) => {
        item.element.classList.add('filter--visible');
    });

    Object.keys(state.current).forEach((key) => {
        state.current[key].item.classList.remove('active');
    });

    state.current = {};
};

/**
 * Handles the filter change
 *
 * @param $filter
 * @returns {void}
 */
const handleFilterChange = ($filter) => {
    const key = $filter.target.getAttribute('data-filter-target');
    const value = $filter.target.innerText.toLowerCase();

    state.filters.forEach((filter) => {
        if (filter.filter === key) {
            filter.value = value;

            if (value === '') {
                filter.selected = false;
                state.filterCount--;
            } else if (!filter.selected) {
                filter.selected = true;
                state.filterCount++;
            }
        }
    });

    if (state.current.hasOwnProperty(key)) {
        state.current[key].item.classList.remove('active');
    }

    state.current[key] = {
        item: $filter.target,
        value,
    };
    $filter.target.classList.add('active');

    return handleStateChange();
};

/**
 * Initliazes the filters
 *
 * @returns {void}
 */
export const init = () => {
    Array.prototype.forEach.call($filters, ($filter) => {
        $filter.addEventListener('click', ($filter) =>
            handleFilterChange($filter)
        );

        state.filters.push({
            element: $filter,
            filter: $filter.getAttribute('data-filter-target').toLowerCase(),
            value: '',
            selected: false,
        });
    });

    Array.prototype.forEach.call($elements, ($element) => {
        const filter =
            JSON.parse($element.getAttribute('data-filter-item')) || {};

        state.items.push({
            element: $element,
            filter,
        });
    });

    $reset?.addEventListener('click', () => resetElements());
};
