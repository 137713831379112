import L from 'leaflet';

/**
 * @type {NodeListOf<Element>}
 */
const $maps = document.querySelectorAll('.js-map');

/**
 * Initiliazes the map.
 *
 * @returns {void}
 */
export const init = () => {
    Array.prototype.forEach.call($maps, ($element) => {
        const map = L.map($element, {
            zoomControl: true,
            dragging: true,
            touchZoom: true,
            doubleClickZoom: false,
            scrollWheelZoom: false,
            boxZoom: true,
            keyboard: false,
        });

        L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
            attribution:
                '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
        }).addTo(map);

        let locations = [];
        try {
            locations = JSON.parse($element.getAttribute('data-map'));
        } catch (e) {
            console.error(e);
        }

        if (locations.length > 0) {
            const group = [];
            const icon = L.icon({
                iconUrl: '/assets/images/marker-lb.svg',
                iconSize: [20, 20], // size of the icon
                iconAnchor: [10, 20], // point of the icon which will correspond to marker's location
                popupAnchor: [0, -20], // point from which the popup should open relative to the iconAnchor
            });

            locations.forEach((location) => {
                // noinspection JSUnresolvedVariable
                const circle = L.marker([location.lat, location.lon], {
                    icon,
                    riseOnHover: true,
                })
                    .addTo(map)
                    .bindPopup(location.tooltip);

                group.push(circle);
            });

            const featureGroup = new L.featureGroup(group);
            map.fitBounds(featureGroup.getBounds());
        }
    });
};
