/**
 * Stores the current state.
 *
 * @type {{}}
 */
const state = {};

/**
 * Directions.
 *
 * @type {{left: string, right: string}}
 */
const dir = {
    left: 'left',
    right: 'right'
};

/**
 * Scrolls per given pixel.
 *
 * @type {number}
 */
const scrollWidth = 272;

/**
 * Handles the button click.
 *
 * @param event
 * @param direction
 * @param id
 * @returns {void}
 */
const handleClick = (event, direction, id) => {
    event.preventDefault();

    const $scroller = state[id].element;
    const currentPosition = state[id].element.scrollLeft;

    if (direction === dir.right) {
        $scroller.scrollLeft = currentPosition + scrollWidth;
    } else if (direction === dir.left) {
        $scroller.scrollLeft = currentPosition - scrollWidth;
    }
};

/**
 * Initiliazes the scroller controls for scrolling divs.
 *
 * @returns {void}
 */
export const init = () => {
    const $scroller = document.querySelectorAll('[data-control-id]');

    Array.prototype.forEach.call($scroller, $scroll => {
        const id = $scroll.getAttribute('data-control-id');
        const $left = document.querySelector(
            `[data-control-target="${id}"][data-control-type="left"]`
        );
        const $right = document.querySelector(
            `[data-control-target="${id}"][data-control-type="right"]`
        );

        $left?.addEventListener('click', event =>
            handleClick(event, 'left', id)
        );
        $right?.addEventListener('click', event =>
            handleClick(event, 'right', id)
        );

        state[id] = {
            id,
            element: $scroll,
            left: $left,
            right: $right
        };
    });
};
